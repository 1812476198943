<template>
    <v-col cols="6">
        <v-card-title class="text-h5 text-capitalize primary--text font-weight-bold d-flex align-center justify-center">
            Inspiration fund
        </v-card-title>
        <v-card elevation="7" class="mb-3 pa-3">
            <v-row class="ma-0 pa-0" align="center" justify="space-around">
                <div class="text-subtitle-1 text-break primary--text font-weight-bold">
                    Total Added: ${{ $filters.currencyComma(metaTotal.total_added) }}
                </div>
                <div class="text-subtitle-1 text-break primary--text font-weight-bold">
                    Total Spent: ${{ $filters.currencyComma(metaTotal.total_spent) }}
                </div>
                <div class="text-subtitle-1 text-break primary--text font-weight-bold">
                    Balance: ${{ $filters.currencyComma(metaTotal.balance) }}
                </div>
                <v-btn text class="text-capitalize text-subtitle-2 target-button" color="info"
                    @click="addLedgers('Add')" :loading="loadingAdd">
                    <v-icon dark class="mb-1" large color="info">{{openAdd ? 'mdi-content-save-plus-outline' : 'mdi-plus'}}</v-icon>
                    {{openAdd ? 'Save' : 'Add'}}
                </v-btn>
                <v-btn text class="text-capitalize text-subtitle-2 target-button" color="info"
                     @click="addLedgers('Spend')" :loading="loadingSpend">
                    <v-icon dark class="mb-1" large color="info">{{openSpend ? 'mdi-cash-plus' : 'mdi-currency-usd'}}</v-icon>
                    {{openSpend ? 'Submit' : 'Spend'}}
                </v-btn>
            </v-row>
            <v-expand-transition v-if="openAdd ||  openSpend">
                <v-row class="ma-0 pa-0">
                    <v-col cols="6">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Amount</v-subheader>
                        <v-text-field outlined label="" placeholder="Enter Amount" hide-details="auto" type="number" min="0"
                            v-model.number="model.amount" @focus="$helpers.avoidInputScrollable" @keydown="$helpers.avoidInputArrowKeyPress"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Description</v-subheader>
                        <v-text-field outlined label="" placeholder="Enter Description" hide-details="auto" v-model.trim="model.description"></v-text-field>
                    </v-col>
                </v-row>
            </v-expand-transition>
        </v-card>
        <v-toolbar
            dark
            color="primary"
        >
            <v-text-field
                v-model.trim="search"
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
            ></v-text-field>
            <div class="mx-3 text-subtitle-1" v-if="metaTotal.total_amount" v-currency="metaTotal.total_amount">Total:</div>
        </v-toolbar>
        <datatable @metaResp="metaTotal=$event" ref="dtComponent" :modelData="dtData" :search="search"
            @interSectLoad="$refs.dtComponent.interSectLoading = $event"/>
    </v-col>
</template>

<script>
import { mapActions } from 'vuex';

import { CurrencyFormat } from "@/directives";

export default {
    components: {
        Datatable: () => import("@/components/common/datatable"),
    },
    directives: {
        'currency': CurrencyFormat,
    },
    data: () => ({
        openAdd: false,
        openSpend: false,
        loadingAdd: false,
        loadingSpend: false,
        search: '',
        dtData: {
            searchDisable: true,
            headers: [
                { text: 'Date/Time', value: 'datefield', field: 'created_at'},
                { text: 'Description', value: 'description'},
                { text: 'Amount Added', value: 'amount', field: 'amount_added'},
                { text: 'Amount Spent', value: 'amount', field: 'amount_spent'},
            ],
            footerHide: true,
            interSectScroll: true,
            interSectId: 'tally-fund-table',
            interSectClass: 'tally-scroll-table',
            expandable: false,
            pageLink: `${window.VUE_APP_MS_URL}donate/ledgers?page=1`
        },
        model: {},
        metaTotal: {}
    }),
    mounted() {
        this.wsActionUpdate();
    },
    methods: {
        ...mapActions(['updateLedgersIF']),

        addLedgers(field) {
            const type = field === 'Spend' ? 'S' : 'A';
            this[`open${type === 'A' ? 'Spend' : 'Add'}`] = false;
            if (this[`open${field}`]) {
                this[`loading${field}`] = true;
                const reqData = {...this.model};
                this.model = {};
                reqData['type'] = type;
                this.updateLedgersIF(reqData).then(resp => {
                    this.$refs.dtComponent.pageLoading(this.dtData.pageLink);
                    this[`open${field}`] = this[`loading${field}`] = false;
                }).catch(err => this[`loading${field}`] = false);
            }
            this[`open${field}`] = !this[`open${field}`];
        },
        wsActionUpdate() {
            Echo.channel('ledger').listen('.created',(ledgerItem) => {
               this.ledgerItemChanges(ledgerItem);
            });
            Echo.channel('ledger').listen('.updated',(ledgerItem) => {
               this.ledgerItemChanges(ledgerItem);
            });
            Echo.channel('ledger').listen('.deleted',(ledgerItem) => {
                const index = this.ledgerItemChanges(ledgerItem, 'deleted');
                if (index !== -1) {
                    this.$refs.dtComponent.dtLists.splice(index, 1);
                }
            });
        },
        ledgerItemChanges(ledgerIem, type) {
            const itemIndex = this.$refs.dtComponent.dtLists.length !== 0 ?
                this.$refs.dtComponent.dtLists.findIndex(item => item.id === ledgerIem.id) : -1;
            if (type) {
                return itemIndex;
            } else if (itemIndex !== -1) {
                this.$refs.dtComponent.dtLists[itemIndex] = ledgerIem;
                return;
            }
            this.$refs.dtComponent.dtLists.unshift(ledgerIem);
        }
    },
    beforeDestroy() {
        Echo.leaveChannel('ledger');
    }
}
</script>
